import Konva from "konva";
import { Shape, ShapeConfig } from "konva/lib/Shape";
import { Stage } from "konva/lib/Stage";
type ImageSize = {
    width: number;
    height: number;
}

type labelType = {
    key: string;
    type: string;
    name: string;
    city: string;
    qrcode: string;
    sign: string;
}

function parseImage( imgData: string ): Promise<HTMLImageElement> {
    const img = new Image(); 
    return new Promise(function(resolve)  {   
        img.onload = function() {
            resolve(img);
        }   
        img.src = imgData;
    })
}

// const rotatePoint = ({ x, y }, rad: number) => {
//     const rcos = Math.cos(rad);
//     const rsin = Math.sin(rad);
//     return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
//   };
  
//   // will work for shapes with top-left origin, like rectangle
//   function rotateAroundCenter(node: Konva.Node, rotation: number) {
//     //current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
//     const topLeft = { x: -node.width() / 2, y: -node.height() / 2 };
//     const current = rotatePoint(topLeft, Konva.getAngle(node.rotation()));
//     const rotated = rotatePoint(topLeft, Konva.getAngle(rotation));
//     const dx = rotated.x - current.x,
//       dy = rotated.y - current.y;
  
//     node.rotation(rotation);
//     node.x(node.x() + dx);
//     node.y(node.y() + dy);
//   }
  


async function labelSocialOne (label: labelType, extraText = ''): Promise<Stage>{


    console.log(`LabelFour -> Janela: Largura ${window.innerWidth}, Altura: ${window.innerHeight}`);

    const largura = Math.floor(window.innerWidth * 0.92);
    //const altura = Math.floor(largura*1.5);

    const stage = new Konva.Stage({
        container: "etiqueta",
        width: largura,
        height: largura*86/62,
    });
    
    //o qrcode vai fica com a fração de 12/20 da largura da etiqueta

    const qrCodeImage = await parseImage(label.qrcode);
    const qrcodeScale = Math.floor((largura)*1000/(qrCodeImage.height))/1000; 

    const fontSize = 17;

    // const calcFontSize = (stage.height() - qrCodeImage.height * qrcodeScale)/4.75;
    // //quatro linhas de fontes + uma linha com 75% do tamanho (a assinatura)
    // if (calcFontSize < fontSize) {
    //     fontSize = Math.floor(calcFontSize);
    //     console.log(`Fonte ajustada para ${fontSize}`);
    // } else {
    //     console.log(`Fonte poderia ter até ${calcFontSize}. Não ajustada`);
    // }
    
    const layer = new Konva.Layer();

    const background = new Konva.Layer();
    const backRect = new Konva.Rect({
        x:0,
        y:0,
        width: stage.width(),
        height: stage.height(),
        fill: 'white'
    });

    background.add(backRect);
    stage.add(background);

    stage.add(layer);

    //console.log(`Qrcode scale = ${qrcodeScale}`)

    let groupStartY = 0;
    const header = new Konva.Text({
        x: 0,
        y: groupStartY,
        text: `Me adicione nos Contatos`,
        align: 'center',
        fontSize: fontSize,
        fontFamily: 'Calibri',
        fill: 'black',
        fontStyle: 'bold'
    });

    layer.add(header);
    header.setAttr('x', largura/2);
    header.offsetX(header.width()/2);

    groupStartY = groupStartY + fontSize + 10;

   

    function createQrcode(): Promise<Shape<ShapeConfig>>{
        return new Promise(function(resolve) {
            Konva.Image.fromURL(label.qrcode, function (elem: Shape<ShapeConfig>) {
                elem.setAttrs({
                    x: 0,
                    y: groupStartY,
                    scaleX: qrcodeScale,
                    scaleY: qrcodeScale
                });
                resolve(elem);
            });
        });
    }

    const qrcodeRef = await createQrcode();
    layer.add(qrcodeRef);

    console.log('Atributos do QR-CODE')
    console.log((qrcodeRef.attrs));
    console.log((qrcodeRef.size()));

    //console.log(`pix Logo scale = ${pixLogoScale}`)

    groupStartY = groupStartY + Math.floor(qrcodeRef.size().height * qrcodeScale) + 10;

    // console.log('Atributos do Logo Pix')
    // console.log((logoPixRef.attrs));
    // console.log((logoPixRef.size()));

    //console.log(`Font Size determinada: ${fontSize}`);

    const signing = new Konva.Text({
        x: Math.floor(largura/2),
        y: groupStartY,
        text: `Assinatura: ${label.sign}`,
        align: 'center',
        fontSize: Math.floor(fontSize*0.75),
        fontFamily: 'Consolas',
        // scaleX: signFontScale,
        // scaleY: signFontScale,
        fill: 'black',
    });
    signing.offsetX(signing.width()/2);
    layer.add(signing);

    groupStartY = groupStartY + fontSize;

    //--------------------------------
    const groupAsk = new Konva.Group();
    const pedido = new Konva.Text({
        x: 0,
        y: 0,
        text: 'Peça o seu QR-Code já',
        align: 'center',
        fontSize: fontSize - 2,
        fontFamily: 'Calibri',
        fill: 'black',
    });
    groupAsk.add(pedido);

    function createLogo() {
        return new Promise(function(resolve) {
            const logoImg = new Image();
            logoImg.onload = function() {
                const logoScale = Math.floor((fontSize)*1000/(logoImg.height))/1000;
                const konvaObj = new Konva.Image({
                    x: pedido.width() + 5,
                    y: 0,
                    image: logoImg,
                    scaleX: logoScale,
                    scaleY: logoScale,
                });
                resolve(konvaObj);
            }
            logoImg.src = '/assets/icon/instagram.png';
        })
    }

    const logoRef = await createLogo() as Konva.Shape;
    groupAsk.add(logoRef);

    //console.log(`Condições do Logo Insta: ${logoSize} ${logoScale}`);

    const instagram = new Konva.Text({
        x: pedido.width() + 10 + logoRef.size().width * logoRef.attrs.scaleX,
        y: 0,
        text: '@enviepix',
        align: 'center',
        fontSize: fontSize,
        fontFamily: 'Calibri',
        fill: 'black',
        fontStyle: 'bold'
    });
    //group.setPosition();
    groupAsk.add(instagram);

    const groupAskWidth = pedido.width() + 10 + logoRef.size().width * logoRef.attrs.scaleX + instagram.width()
    
    groupAsk.setAttr('x',(largura - groupAskWidth)/2);
    groupAsk.setAttr('y', groupStartY);

    layer.add(groupAsk);
    groupStartY = groupStartY + fontSize;
    //-----------------------------------


    const site = new Konva.Text({
        x: Math.floor(largura/2),
        y: groupStartY,
        text: 'http://enviepix.com',
        align: 'center',
        fontSize: fontSize,
        fontFamily: 'Calibri',
        fill: 'black',
        fontStyle: 'bold'
    });
    site.offsetX(site.width()/2);
    layer.add(site);
    stage.add(layer);

    if (extraText !=='') {
        console.log('Desenhando o draft')
        const extraLayer = new Konva.Layer();
        const draft = new Konva.Text({
            x: largura/2,
            y: qrcodeRef.height() * qrcodeRef.scaleY()/2,
            text: extraText,
            align: 'center',
            fontSize: qrcodeRef.width() * qrcodeRef.scaleX()/3,
            fontFamily: 'Calibri',
            fill: 'red',
            fontStyle: 'bold'
        });
        draft.offsetX(draft.width()/2);
        extraLayer.add(draft)
        stage.add(extraLayer)
    }
    // layer.offsetX(layer.width()/2);
    // layer.offsetY(layer.height()/2);
    // layer.rotate(90);
    // layer.setAttr('Y', layer.width()/2);
    return stage;
}

export default labelSocialOne;
