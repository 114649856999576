
import {
    IonItem,
    IonButton,
    IonLabel,
    IonSelect,
    IonSelectOption,
    //toastController
} from "@ionic/vue";

import { onMounted, ref, watch } from "vue";
import BaseLayout from "@/components/BaseLayout.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getAuth } from "firebase/auth";
import print from "print-js";

import { Stage } from "konva/lib/Stage";

import labelThree from "@/layouts/pix/labelThree";
import labelFour from "@/layouts/pix/labelFour";
import labelFive from "@/layouts/pix/labelFive";

type labelType = {
    key: string;
    type: string;
    name: string;
    city: string;
    qrcode: string;
};

export default {
    name: "Etiqueta",
    components: {
        IonItem,
        IonButton,
        IonLabel,
        IonSelect,
        IonSelectOption,
        BaseLayout,
    },

    setup() {
        //recebe context, watch,

        const labelImg = ref();
        const layout = ref("labelFive");
        const labelSize = ref("");

        let stage: Stage;
        const store = useStore();
        const router = useRouter();

        let label = store.getters.getLabel;
        const backLink = ref("/" + store.getters.business + "/qrgenerator");

        setInterval(() => {
            if (!getAuth().currentUser) router.push("/signin");
        }, 2000);

        // async function openToast(message: string) {
        //     const toast = await toastController
        //         .create({
        //         message: message,
        //         duration: 2000
        //         })
        //     return toast.present();
        // }

        async function buildLabel(extraText: string) {
            label = store.getters.getLabel;
            if (label.qrcode) {
                if (layout.value == "labelThree") {
                    stage = await labelThree(label, extraText);
                    labelSize.value = "62mm x 62mm";
                }
                if (layout.value == "labelFour") {
                    stage = await labelFour(label, extraText);
                    labelSize.value = "62mm x 128mm";
                }
                if (layout.value == "labelFive") {
                    stage = await labelFive(label, extraText);
                    labelSize.value = "62mm x 88mm";
                }
                stage.show();
            }
        }

        onMounted(async () => {
            label = store.getters.getLabel;
            await buildLabel(""); //DRAFT
        });

        watch(layout, () => {
            buildLabel("");
        });

        async function savePNG() {
            await buildLabel("");
            labelImg.value = stage.toDataURL({
                pixelRatio: 4,
            });
            if (labelImg.value) {
                const link = document.createElement("a");
                link.download = `${label.key.replace("/", "_")}_${(
                    Math.random() + 1
                )
                    .toString(36)
                    .substring(7)}.png`;
                link.href = labelImg.value;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

        async function reload() {
            label = store.getters.getLabel;
            buildLabel("");
        }

        async function postPrint() {
            console.log("Impresso");
        }

        async function printLabel() {
            //https://printjs.crabbly.com/
            print({
                printable: stage.toDataURL({
                    pixelRatio: 4,
                }),
                type: "image",
                imageStyle: "width:100%",
                onPrintDialogClose: postPrint,
            });
        }

        return {
            savePNG,
            printLabel,
            layout,
            labelSize,
            reload,
            backLink,
        };
    },
};
