
import {
    IonItem,
    IonButton,
    IonLabel,
    IonSelect,
    IonSelectOption,
} from "@ionic/vue";

import { onMounted, ref, watch } from "vue";
import BaseLayout from "@/components/BaseLayout.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getAuth } from "firebase/auth";
import print from "print-js";

import { Stage } from "konva/lib/Stage";

import labelOne from "@/layouts/vcard/labelOne";
import { LabelOnFirebase } from '@/types/types';

export default {
    name: "Etiqueta",
    components: {
        IonItem,
        IonButton,
        IonLabel,
        IonSelect,
        IonSelectOption,
        BaseLayout,
    },
    setup() {
        const labelImg = ref();
        const layout = ref("labelOne");
        const labelSize = ref("62mm x 88mm");

        let stage: Stage;
        const store = useStore();
        const router = useRouter();

        let label = store.getters.getLabel as LabelOnFirebase;
        const backLink = '/' + store.getters.getBusiness + '/qrgenerator';

        setInterval(() => {
            if (!getAuth().currentUser) router.push("/signin");
        }, 2000);

        async function buildLabel(extraText: string) {
            label = store.getters.getLabel;
            if (label.qrcode) {
                if (layout.value == "labelOne") {
                    stage = await labelOne(label, extraText);
                    stage.show();
                    labelSize.value = "62mm x 62mm";
                }
            }
        }

        onMounted(async () => {
            label = store.getters.getLabel;
            await buildLabel(""); //DRAFT
        });

        watch(layout, () => {
            buildLabel("");
        });

        async function savePNG() {
            await buildLabel("");
            labelImg.value = stage.toDataURL({
                pixelRatio: 4,
            });
            if (labelImg.value) {
                const link = document.createElement("a");
                link.download = `${label.name.replaceAll(" ", "_")}_${(
                    Math.random() + 1
                )
                    .toString(36)
                    .substring(7)}.png`;
                link.href = labelImg.value;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

        async function reload() {
            label = store.getters.getLabel;
            await buildLabel("");
        }

        async function postPrint() {
            console.log("Impresso");
        }

        async function printLabel() {
            //https://printjs.crabbly.com/
            print({
                printable: stage.toDataURL({
                    pixelRatio: 4,
                }),
                type: "image",
                imageStyle: "width:100%",
                onPrintDialogClose: postPrint,
            });
        }

        return {
            savePNG,
            printLabel,
            layout,
            labelSize,
            reload,
            backLink,
        };
    },
};

