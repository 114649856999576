
import Konva from "konva";
import { Shape, ShapeConfig } from "konva/lib/Shape";
import { Stage } from "konva/lib/Stage";
import { formatCPF, formatCNPJ, formatCEL, formateCHAVE } from "@/libs/formats";
type ImageSize = {
    width: number;
    height: number;
}

type labelType = {
    key: string;
    type: string;
    name: string;
    city: string;
    qrcode: string;
    sign: string;
}

function parseImage( imgData: string ): Promise<HTMLImageElement> {
    const img = new Image(); 
    return new Promise(function(resolve)  {   
        img.onload = function() {
            resolve(img);
        }   
        img.src = imgData;
    })
}


// const rotatePoint = ({ x, y }, rad: number) => {
//     const rcos = Math.cos(rad);
//     const rsin = Math.sin(rad);
//     return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
//   };
  
//   // will work for shapes with top-left origin, like rectangle
//   function rotateAroundCenter(node: Konva.Node, rotation: number) {
//     //current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
//     const topLeft = { x: -node.width() / 2, y: -node.height() / 2 };
//     const current = rotatePoint(topLeft, Konva.getAngle(node.rotation()));
//     const rotated = rotatePoint(topLeft, Konva.getAngle(rotation));
//     const dx = rotated.x - current.x,
//       dy = rotated.y - current.y;
  
//     node.rotation(rotation);
//     node.x(node.x() + dx);
//     node.y(node.y() + dy);
//   }
  


async function labelFour (label: labelType, extraText = ''): Promise<Stage>{

    let typeLabel = '';

    switch (label.type) {
        case 'cpf':
            typeLabel = 'CPF:';
            label.key = formatCPF(label.key);
            break;
        case 'email':
            typeLabel = 'e-mail:';
            break;
        case 'cnpj':
            typeLabel = 'CNPJ:';
            label.key = formatCNPJ(label.key);
            break;
        case 'telefone':
            typeLabel = 'pelo CEL';
            label.key = formatCEL(label.key);
            break;
        case 'chave':
            typeLabel ='';
            label.key = formateCHAVE(label.key);
            break;
    } 

    console.log(`LabelFour -> Janela: Largura ${window.innerWidth}, Altura: ${window.innerHeight}`);

    const largura = Math.floor(window.innerWidth * 0.90);
    const altura = Math.floor(largura/2);

    const stage = new Konva.Stage({
        container: "etiqueta",
        width: largura/2,
        height: altura*2,
    });
    
    const groupOnRight = new Konva.Group();

    //o qrcode vai fica com a fração de 12/20 da largura da etiqueta

    const fontSize = 10;
    
    const layer = new Konva.Layer();

    const background = new Konva.Layer();
    const backRect = new Konva.Rect({
        x:0,
        y:0,
        width: stage.width(),
        height: stage.height(),
        fill: 'white'
    });

    background.add(backRect);
    stage.add(background);
    
    stage.add(layer);

    //console.log(`Qrcode scale = ${qrcodeScale}`)

    const qrCodeImage = await parseImage(label.qrcode);
    const qrcodeScale = Math.floor((altura)*1000/(qrCodeImage.height))/1000;    

    function createQrcode(): Promise<Shape<ShapeConfig>>{
        return new Promise(function(resolve) {
            Konva.Image.fromURL(label.qrcode, function (elem: Shape<ShapeConfig>) {
                elem.setAttrs({
                    x: 0,
                    y: 0,
                    scaleX: qrcodeScale,
                    scaleY: qrcodeScale
                });
                resolve(elem);
            });
        });
    }

    const qrcodeRef = await createQrcode();
    layer.add(qrcodeRef);

    console.log('Atributos do QR-CODE')
    console.log((qrcodeRef.attrs));
    console.log((qrcodeRef.size()));

    //console.log(`pix Logo scale = ${pixLogoScale}`)

    const groupWidth = Math.floor(largura - qrcodeRef.size().width * qrcodeScale);
    const groupStartX = Math.floor(qrcodeRef.size().width * qrcodeScale) + 5;

    const header = new Konva.Text({
        x: groupWidth/2,
        y: fontSize,
        text: `Pague via PIX lendo o QR-Code ou digite\n${typeLabel} ${label.key}`,
        align: 'center',
        fontSize: fontSize,
        fontFamily: 'Calibri',
        fill: 'black',
        fontStyle: 'bold'
    });

    groupOnRight.add(header);
    //header.setAttr('x', groupWidth/2);
    header.offsetX(header.width()/2);

    const pixLogo = await parseImage('/assets/icon/pix-logo.png');
    const pixLogoScale = Math.floor((groupWidth)*1000/(pixLogo.width))/1000;
    //const pixLogoScale = Math.floor((pixLogo.height)*1000/(altura))/1000;

    function createPixLogo() {
        return new Promise(function(resolve) {
            const logoImg = new Image();
            logoImg.onload = function() {
                const konvaObj = new Konva.Image({
                    x: 0,
                    y: Math.floor(altura/2 - (pixLogo.height * pixLogoScale)/2),
                    image: logoImg,
                    scaleX: pixLogoScale,
                    scaleY: pixLogoScale,
                });
                resolve(konvaObj);
            }
            logoImg.src = '/assets/icon/pix-logo.png';
        })
    }

    const logoPixRef = await createPixLogo() as Konva.Shape;
    groupOnRight.add(logoPixRef);
    //console.log('Atributos')
    //console.log((logoPixRef.attrs));
    //console.log((logoPixRef.size()));

    //console.log(`Font Size determinada: ${fontSize}`);

    const signing = new Konva.Text({
        x: Math.floor(groupWidth/2),
        y: altura - fontSize*4,
        text: `Assinatura: ${label.sign}`,
        align: 'center',
        fontSize: Math.floor(fontSize - 4),
        fontFamily: 'Consolas',
        // scaleX: signFontScale,
        // scaleY: signFontScale,
        fill: 'black',
    });
    signing.offsetX(signing.width()/2);
    groupOnRight.add(signing);

    //--------------------------------
    const groupAsk = new Konva.Group();
    const pedido = new Konva.Text({
        x: 0,
        y: 0,
        text: 'Peça o seu QR-Code já',
        align: 'center',
        fontSize: fontSize - 2,
        fontFamily: 'Calibri',
        fill: 'black',
    });
    groupAsk.add(pedido);

    function createLogo() {
        return new Promise(function(resolve) {
            const logoImg = new Image();
            logoImg.onload = function() {
                const logoScale = Math.floor((fontSize)*1000/(logoImg.height))/1000;
                const konvaObj = new Konva.Image({
                    x: pedido.width() + 5,
                    y: 0,
                    image: logoImg,
                    scaleX: logoScale,
                    scaleY: logoScale,
                });
                resolve(konvaObj);
            }
            logoImg.src = '/assets/icon/instagram.png';
        })
    }

    const logoRef = await createLogo() as Konva.Shape;
    groupAsk.add(logoRef);

    //console.log(`Condições do Logo Insta: ${logoSize} ${logoScale}`);

    const instagram = new Konva.Text({
        x: pedido.width() + 10 + logoRef.size().width * logoRef.attrs.scaleX,
        y: 0,
        text: '@enviepix',
        align: 'center',
        fontSize: fontSize,
        fontFamily: 'Calibri',
        fill: 'black',
        fontStyle: 'bold'
    });
    //group.setPosition();
    groupAsk.add(instagram);

    const groupAskWidth = pedido.width() + 10 + logoRef.size().width * logoRef.attrs.scaleX + instagram.width()
    
    groupAsk.setAttr('x',(groupWidth - groupAskWidth)/2);
    groupAsk.setAttr('y', altura - fontSize*3);

    groupOnRight.add(groupAsk);
    //-----------------------------------


    const site = new Konva.Text({
        x: Math.floor(groupWidth/2),
        y: Math.floor(altura-fontSize*2), //group.attrs.y + fontSize,
        text: 'http://enviepix.com',
        align: 'center',
        fontSize: fontSize,
        fontFamily: 'Calibri',
        fill: 'black',
        fontStyle: 'bold'
    });
    site.offsetX(site.width()/2);
    groupOnRight.add(site);

    groupOnRight.setAttr('x',groupStartX + groupWidth/2);
    groupOnRight.offsetX(groupWidth/2)
    layer.add(groupOnRight);

    layer.offsetX(layer.width()/2);
    layer.offsetY(layer.height()/2);
    layer.rotate(90);
    layer.setAttr('Y', layer.width()/2);

    stage.add(layer);

    return stage;
}

export default labelFour;