
import Konva from "konva";
import { Shape, ShapeConfig } from "konva/lib/Shape";
import { Stage } from "konva/lib/Stage";
import { formatCPF, formatCNPJ, formatCEL, formateCHAVE } from "@/libs/formats";
type ImageSize = {
    width: number;
    height: number;
}

type labelType = {
    key: string;
    type: string;
    name: string;
    city: string;
    qrcode: string;
    sign: string;
}

function parseImage( imgData: string ): Promise<HTMLImageElement> {
    const img = new Image(); 
    return new Promise(function(resolve)  {   
        img.onload = function() {
            resolve(img);
        }   
        img.src = imgData;
    })
}



async function labelThree (label: labelType, extraText = ''): Promise<Stage>{

    let typeLabel = '';

    switch (label.type) {
        case 'cpf':
            typeLabel = 'CPF:';
            label.key = formatCPF(label.key);
            break;
        case 'email':
            typeLabel = 'e-mail:';
            break;
        case 'cnpj':
            typeLabel = 'CNPJ:';
            label.key = formatCNPJ(label.key);
            break;
        case 'telefone':
            typeLabel = 'pelo CEL';
            label.key = formatCEL(label.key);
            break;
        case 'chave':
            typeLabel ='';
            label.key = formateCHAVE(label.key);
            break;
    } 


    //console.log(`Largura ${window.innerWidth}, Altura: ${window.innerHeight}`);

    const largura = Math.floor(window.innerWidth * 0.90);
    const altura = Math.floor(largura*62/62); // 62/62 é a proporção da tiqueta

    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //const logo = await imgageSize(whatsAppDefault);

    const stage = new Konva.Stage({
        container: "etiqueta",
        width: largura,
        height: altura
    });
    
    const group = new Konva.Group();

    //o qrcode vai fica com a fração de 12/20 da largura da etiqueta

    const fontSize = 17;

    const layer = new Konva.Layer();

    const background = new Konva.Layer();
    const backRect = new Konva.Rect({
        x:0,
        y:0,
        width: stage.width(),
        height: stage.height(),
        fill: 'white'
    });

    background.add(backRect);
    stage.add(background);
    
    stage.add(layer);


    const header = new Konva.Text({
        x: 0,
        y: 0,
        text: `Pague via PIX lendo o QR-Code ou digite... \n${typeLabel} ${label.key}`,
        align: 'center',
        fontSize: fontSize,
        fontFamily: 'Calibri',
        fill: 'black',
        fontStyle: 'bold'
    });

    layer.add(header);
    header.setAttr('x', largura/2);
    header.offsetX(header.width()/2);

    //console.log(`Qrcode scale = ${qrcodeScale}`)
    const linesOfFontSize = fontSize * 5; //total de espaço pelas linhas de texto antes e depois do logo
    const qrCodeImage = await parseImage(label.qrcode);
    const qrcodeScale = Math.floor((altura - linesOfFontSize)*1000/(qrCodeImage.height))/1000;

    function createQrcode(): Promise<Shape<ShapeConfig>>{
        return new Promise(function(resolve) {
            Konva.Image.fromURL(label.qrcode, function (elem: Shape<ShapeConfig>) {
                elem.setAttrs({
                    x: 0,
                    y: fontSize*2,
                    scaleX: qrcodeScale,
                    scaleY: qrcodeScale
                });
                layer.add(elem);
                resolve(elem);
            });
        });
    }

    const qrcodeRef = await createQrcode();
    //console.log('Atributos')
    //console.log((qrcodeRef.attrs));
    //console.log((qrcodeRef.size()));

    const pixLogo = await parseImage('/assets/icon/pix-logo-vertical.png');
    const pixLogoScale = Math.floor((largura - qrcodeRef.size().width * qrcodeScale)*1000/(pixLogo.width))/1000;

    //console.log(`pix Logo scale = ${pixLogoScale}`)

    function createPixLogo() {
        return new Promise(function(resolve) {
            const logoImg = new Image();
            logoImg.onload = function() {
                const konvaObj = new Konva.Image({
                    x: Math.floor(qrcodeRef.size().width * qrcodeScale) + 5,
                    y: Math.floor(altura/2 - (pixLogo.height * pixLogoScale)/2),
                    image: logoImg,
                    scaleX: pixLogoScale,
                    scaleY: pixLogoScale,
                });
                resolve(konvaObj);
            }
            logoImg.src = '/assets/icon/pix-logo-vertical.png';
        })
    }

    const logoPixRef = await createPixLogo() as Konva.Shape;
    layer.add(logoPixRef);
    //console.log('Atributos')
    //console.log((logoPixRef.attrs));
    //console.log((logoPixRef.size()));

    //console.log(`Font Size determinada: ${fontSize}`);

    const signing = new Konva.Text({
        x: Math.floor(largura/2),
        y: altura - fontSize*3,
        text: `Assinatura: ${label.sign}`,
        align: 'center',
        fontSize: Math.floor(fontSize - 4),
        fontFamily: 'Consolas',
        // scaleX: signFontScale,
        // scaleY: signFontScale,
        fill: 'black',
    });
    signing.offsetX(signing.width()/2);
    layer.add(signing);

    const pedido = new Konva.Text({
        x: 0,
        y: 0,
        text: 'Peça o seu QR-Code já',
        align: 'center',
        fontSize: fontSize - 2,
        fontFamily: 'Calibri',
        fill: 'black',
    });
    //console.log(`Largura do Pedido: ${pedido.width()}`)
    group.add(pedido);

    //console.log(`Preparando logo do insta`);


    function createLogo() {
        return new Promise(function(resolve) {
            const logoImg = new Image();
            logoImg.onload = function() {
                const logoScale = Math.floor((fontSize)*1000/(logoImg.height))/1000;
                const konvaObj = new Konva.Image({
                    x: pedido.width() + 5,
                    y: 0,
                    image: logoImg,
                    scaleX: logoScale,
                    scaleY: logoScale,
                });
                resolve(konvaObj);
            }
            logoImg.src = '/assets/icon/instagram.png';
        })
    }

    const logoRef = await createLogo() as Konva.Shape;
    group.add(logoRef);

    const logoSize = logoRef.size();
    const logoScale = logoRef.attrs.scaleX;

    //console.log(`Condições do Logo Insta: ${logoSize} ${logoScale}`);

    const instagram = new Konva.Text({
        x: pedido.width() + 10 + logoSize.width * logoScale,
        y: 0,
        text: '@enviepix',
        align: 'center',
        fontSize: fontSize,
        fontFamily: 'Calibri',
        fill: 'black',
        fontStyle: 'bold'
    });
    //group.setPosition();
    group.add(instagram);

    group.offsetX((instagram.width() + pedido.width() + 15 + logoSize.width * logoScale)/2);
    group.setAttr('x', largura/2);
    group.setAttr('y', altura - fontSize*2);

    layer.add(group)

    const site = new Konva.Text({
        x: Math.floor(largura/2),
        y: Math.floor(altura-fontSize), //group.attrs.y + fontSize,
        text: 'http://enviepix.com',
        align: 'center',
        fontSize: fontSize,
        fontFamily: 'Calibri',
        fill: 'black',
        fontStyle: 'bold'
    });
    site.offsetX(site.width()/2);
    layer.add(site);
    stage.add(layer);

    if (extraText !=='') {
        console.log('Desenhando o draft')
        const extraLayer = new Konva.Layer();
        const draft = new Konva.Text({
            x: qrcodeRef.width() * qrcodeRef.scaleX()/2,
            y: qrcodeRef.height() * qrcodeRef.scaleY()/2,
            text: extraText,
            align: 'center',
            fontSize: qrcodeRef.width() * qrcodeRef.scaleX()/3,
            fontFamily: 'Calibri',
            fill: 'red',
            fontStyle: 'bold'
        });
        draft.offsetX(draft.width()/2);
        extraLayer.add(draft)
        stage.add(extraLayer)
    }
    return stage;
}

export default labelThree;