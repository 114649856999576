
//http://www.dbins.com.br/dica/como-funciona-a-logica-da-validacao-do-cpf
export function verifyCPF(CPF: string): boolean {
  if (
    CPF.length != 11 ||
    CPF == "00000000000" ||
    CPF == "11111111111" ||
    CPF == "22222222222" ||
    CPF == "33333333333" ||
    CPF == "44444444444" ||
    CPF == "55555555555" ||
    CPF == "66666666666" ||
    CPF == "77777777777" ||
    CPF == "88888888888" ||
    CPF == "99999999999" ||
    CPF == "01234567890"
  )  return false;

  let soma = 0;
  for (let i = 0; i < 9; i++) soma += parseInt(CPF.charAt(i)) * (10 - i);
  let resto = 11 - (soma % 11);
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(CPF.charAt(9))) 
    return false;

  soma = 0;
  for (let i = 0; i < 10; i++) soma += parseInt(CPF.charAt(i)) * (11 - i);
  resto = 11 - (soma % 11);

  if (resto == 10 || resto == 11) 
    resto = 0;
  if (resto != parseInt(CPF.charAt(10))) 
    return false;
  return true;
}

export function verifyCNPJ(cnpj: string): boolean {
 
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') return false;
     
    if (cnpj.length != 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0)))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(1)))
          return false;
           
    return true;
    
}


export function formatCPF(cpf: string): string {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");
  
    //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatCNPJ(cnpj: string): string {
    //retira os caracteres indesejados...
    cnpj = cnpj.replace(/[^\d]/g, "");
  
    //realizar a formatação...
      return cnpj.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
}

export function formatCEL(fone: string): string {
    //retira os caracteres indesejados...
    fone = fone.replace(/[^\d]/g, "");
  
    //realizar a formatação...
      return fone.replace(/^(\d{2})(\d{5})?(\d{4})?/, "($1)$2-$3");
}

export function formateCHAVE(chave: string): string {
  chave = chave.replace(/[^\w]/g, "");
  return chave.replace(/^(\w{8})(\w{4})?(\w{4})?(\w{4})?(\w{12})?/, "$1-$2-$3-$4-$5");
}