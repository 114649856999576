
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, onBeforeUnmount, inject } from 'vue';
import { getAuth } from "firebase/auth";
import { Emitter, EventType } from 'mitt';
import { getAppInfo } from "@/firebase/firebase";
import { useStore } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },

  setup() {
    const emmitter = inject("emitter") as Emitter<Record<EventType, unknown>>;
    const auth = getAuth();
    const store = useStore();

    const authListener = auth.onAuthStateChanged(function(user) {
        if (!user) { // not logged in
            emmitter.emit('logged_out');
        } else {
          emmitter.emit('logged_in');
          getAppInfo().then((resolve) => {
            console.log('Infos obtidas do servidor');
            store.commit('setAppInfo',resolve);
          })
        }
    })

    onBeforeUnmount(() => {
        console.log('App Umounted: Limpando o listner');
        // clear up listener
        authListener()
    })
  }
});
