import crypto, { enc, HmacMD5 } from "crypto-js";

function hexToUtf8(s: string)
{
    const str = s as string;
    return decodeURIComponent(
        
        str.replace(/\s+/g, '') // remove spaces
        .replace(/[0-9a-f]{2}/g, '%$&') as string// add '%' before each 2 characters
    );
}

export function encrypt(message: string, privkey: string, iv: string): string{
    const keyhex  = crypto.enc.Utf8.parse(privkey);
    const ivhex   = crypto.enc.Utf8.parse(iv);
    const enc = crypto.AES.encrypt(message.trim(), keyhex,
        { iv:ivhex, keySize: 16,  mode: crypto.mode.ECB, padding: crypto.pad.Pkcs7});
    return enc + "";
}

export function decrypt(message: string, privkey: string, iv: string): string{
    const keyhex  = crypto.enc.Utf8.parse(privkey);
    const ivhex   = crypto.enc.Utf8.parse(iv);
    const dec = crypto.AES.decrypt(message,keyhex, 
        {iv:ivhex, keySize: 16,  mode: crypto.mode.ECB, padding: crypto.pad.Pkcs7});
    return hexToUtf8(dec.toString());
}

//https://fireflysemantics.medium.com/digitally-signing-objects-with-cryptojs-f0996ed753ae
export function fingerprint(message: string, privkey: string): string {
    return enc.Base64.stringify(HmacMD5(message,privkey));
}