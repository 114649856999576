


import {
    IonLabel,
    IonInput,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonImg,
    IonCheckbox,
    toastController 
} from "@ionic/vue";

import { defineComponent, ref, reactive, watch, computed } from "vue";

import { useRouter } from 'vue-router';
import { getAuth } from "firebase/auth";
import BaseLayout from "@/components/BaseLayout.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getLabelByKey } from "@/firebase/firebase";
import { Geolocation } from '@capacitor/geolocation';
import  { fingerprint } from "@/libs/signing"
import BrCode from "../../libs/brcode";
import QRCode from "qrcode";
import { verifyCPF, verifyCNPJ, formatCPF, formatCNPJ, formatCEL, formateCHAVE } from "@/libs/formats";
import { useStore } from 'vuex';
import { LabelOnFirebase } from "@/types/types";

export default defineComponent({
    name: "QRGenerator",
    components: {
        IonLabel,
        IonItem,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonButton,
        IonImg,
        BaseLayout,
        IonCheckbox
    },

    setup() {
        const name = ref("");
        const key = ref("");
        const type = ref("");
        const city = ref("SAO PAULO");
        const ammount = ref("");
        const qrcode = ref("");
        const qrtxt = ref("");
        const fec = ref("Q");
        const location = reactive({'longitude': 0, 'latitude': 0, 'accuracy': 0});
        const sign = ref("");
        const nameColor = ref("primary");
        const cityColor = ref("primary");
        const autoCheck = ref(true);
        const canCar = ref(false);

        //const qrcodeString = ref("");
        const store = useStore();
        const router = useRouter();
        const appInfo = store.getters.getAppInfo;

        let privkey = "";
        //let iv = "000";
        if (appInfo && 'signingkey' in appInfo) {
            privkey = appInfo.signingkey;
            //iv = appInfo.iv;
        }

        setInterval(()=>{
        if (! getAuth().currentUser)
            router.push("/signin");
        }, 2000);

        const itsOkToGenerate = computed(() => {
            if (name.value.length > 2 && 
                name.value.length <= 25 &&
                key.value !== "" && 
                type.value !== "" &&
                city.value.length > 2 &&
                city.value.length <= 15) {
                return true;
            }
            return false
        });

        async function openToast(message: string) {
            const toast = await toastController
                .create({
                message: message,
                duration: 2000
                })
            return toast.present();
        }

        async function generate() {
            const brcode = new BrCode(
                key.value,
                ammount.value,
                name.value,
                "",
                type.value,
                city.value
            );
            const result = brcode.generateQRCP();
            return new Promise(function(resolve) {
                QRCode.toDataURL(result, {
                    width: 300,
                    errorCorrectionLevel: fec.value,
                    margin: 1,
                }).then(async (qr: string) => {
                    qrcode.value = qr;
                    qrtxt.value = result;
                    sign.value = fingerprint(key.value, privkey);
                    const coordinates = await Geolocation.getCurrentPosition();
                    location.latitude = coordinates.coords.latitude;
                    location.longitude = coordinates.coords.longitude;
                    location.accuracy = coordinates.coords.accuracy;
                    //qrcodeString.value = result.toString();
                    canCar.value = true;
                });
                resolve(true);
            })
        }

        watch(fec, async () => {
            //await generate();
            //canCar.value = false;
        })
        watch(name, () => {
            name.value.length > 25 ? nameColor.value = "danger" : nameColor.value="primary"
        })
        watch(city, () => {
            city.value.length > 15 ? cityColor.value = "danger" : cityColor.value="primary"
        })



        //verifica se a chave existe no firebase e corrige o tipo pelo formato da chave,
        //se cpf, cnpj, celuar ou email
        async function parseKey() {
            const original = key.value;

            if (autoCheck.value === true) {

                if (key.value.length == 14 && key.value.indexOf('@')==-1 && verifyCNPJ(key.value)) {
                    type.value = "cnpj";
                    key.value = formatCNPJ(key.value);
                    openToast(`Tipo ajustado para CNPJ`);
                }
                if (key.value.length == 11 && key.value.indexOf('@')==-1) { //pode ser celular ou cpf :(
                    if (verifyCPF(key.value)) {
                        type.value = "cpf";
                        key.value = formatCPF(key.value);
                        openToast(`Tipo ajustado para CPF`);
                    }
                    else {
                        if (['7','8','9'].includes(key.value[2])) {
                            type.value="telefone";
                            key.value = formatCEL(key.value);
                            openToast(`Tipo ajustado para TELEFONE`);
                        }
                    }
                }
                if (key.value.indexOf('@')>1 && key.value.indexOf('.')>1)  { 
                    type.value = "email";
                    openToast(`Tipo ajustado para EMAIL`);
                }

                if (key.value.length == 32 ) {
                    type.value = "chave";
                    key.value = formateCHAVE(key.value);
                    openToast(`Tipo ajustado para CHAVE`);

                }
                if (key.value.length == 36 ) {
                    type.value = "chave";
                    openToast(`Tipo ajustado para CHAVE`);
                }
            }

            if (original.length >= 11 || original.indexOf('@')>1) {
                let searchString = original
                if (type.value !== "chave" && type.value !== "email") {
                    searchString = searchString.replace(/\D/g,'');
                }
                const doc = (await getLabelByKey(
                    searchString
                )) as unknown as LabelOnFirebase;

                if (doc) {
                    openToast(`Achei similar na base de dados`);
                    name.value = doc.name;
                    type.value = doc.type;
                    city.value = doc.city;
                    if ('ammount' in doc) ammount.value = doc.ammount;
                    fec.value = doc.fec;
                    if ('location' in doc) {
                        location.latitude = doc.location.latitude;
                        location.longitude = doc.location.longitude;
                        location.accuracy = doc.location.accuracy;
                    }
                    await generate();
                } else {
                    name.value = "";
                    qrcode.value = "";
                    qrtxt.value= "";
                }
            }
        }

        function clear() {
            qrcode.value = "";
            qrtxt.value = "";
            key.value = "";
            name.value = "";
            type.value = "";
            city.value = "SAO PAULO";
            ammount.value = "";
            location.latitude = 0;
            location.longitude = 0;
            location.accuracy = 0;
            canCar.value = false;
        }

        async function doCar() {
            if (itsOkToGenerate.value) {
                await store.commit('setLabel',{
                    key: key.value,
                    city: city.value,
                    name: name.value,
                    type: type.value,
                    ammount: ammount.value,
                    qrcode: qrcode.value,
                    qrtxt: qrtxt.value,
                    location,
                    fec: fec.value,
                    sign : sign.value
                });
                router.push("/car");
            }
        }

        async function loadTest() {
            key.value = "27.945.496/0001-22"; //27945496000122 //08674219756 //45af117c-37b4-4a65-b645-0ccd0caff9c3
            city.value = "RIO DE JANEIRO";
            name.value = "Irfc Consultoria E Servic";
            type.value = "cnpj";
            ammount.value = "";
            await generate();
        }

        return {
            name,
            key,
            type,
            qrcode,
            city,
            ammount,
            fec,
            generate,
            clear,
            doCar,
            loadTest,
            itsOkToGenerate,
            parseKey,
            location,
            nameColor,
            cityColor,
            autoCheck,
            canCar     
        };
    },
});
