import { Merchant } from "steplix-emv-qrcps";


class BrCode {
  key: string;
  amount: string;
  name: string;
  reference: string;
  keyType: string;
  city: string;

  constructor(key: string, amount: string, name: string, reference: string, keyType: string, city: string) {
    this.key = key;
    this.amount = amount;
    this.name = name;
    this.reference = reference;
    this.keyType = keyType;
    this.city = city;
  }

  static formatText(text: string) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  }

  formatName() {
    return BrCode.formatText(this.name);
  }

  formatCity() {
    return BrCode.formatText(this.city);
  }

  formatAmount() {
    return this.amount.replace('.','').replace(',','.').replace(' ','').replace("R$", '');
  }

  formatReference() {
    return BrCode.formatText(this.reference).replace(' ','');
  }

  formatKey() {
    let rkey = this.key;
    const ktype = this.keyType.toLowerCase();

    if (ktype == 'telefone' || ktype == 'cnpj' || ktype == "cpf") {
      // \D é um não digito (non-digit) caractere
      rkey = rkey.replace(/\D/g,'');
    }

    if (ktype == "telefone") {
      rkey = "+55" + rkey
    }

    return rkey
  }

  generateQRCP() {
    const emvqr = Merchant.buildEMVQR();

    emvqr.setPayloadFormatIndicator("01");
    emvqr.setCountryCode("BR")
    emvqr.setMerchantCategoryCode("0000");
    emvqr.setTransactionCurrency("986");
    const merchantAccountInformation = Merchant.buildMerchantAccountInformation();
    merchantAccountInformation.setGloballyUniqueIdentifier("BR.GOV.BCB.PIX");

    merchantAccountInformation.addPaymentNetworkSpecific("01", this.formatKey());

    emvqr.addMerchantAccountInformation("26", merchantAccountInformation);

    if (this.name) {
      emvqr.setMerchantName(this.formatName());
    }

    if (this.city) {
      emvqr.setMerchantCity(this.formatCity());
    }

    if (this.amount && this.amount != '') {
      emvqr.setTransactionAmount(this.formatAmount());
    }

    const additionalDataFieldTemplate = Merchant.buildAdditionalDataFieldTemplate();

    if (this.reference) {
      additionalDataFieldTemplate.setReferenceLabel(this.formatReference());
    }
    else {
      additionalDataFieldTemplate.setReferenceLabel("***");
    }

    emvqr.setAdditionalDataFieldTemplate(additionalDataFieldTemplate);
    return emvqr.generatePayload();
  }
}

export default BrCode;