import dateFormat from "dateformat";

import { LabelOnFirebase } from "@/types/types";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
    getFirestore,
    collection,
    getDoc,
    getDocs,
    setDoc,
    doc,
    query,
    where,
    orderBy,
    limit,
    collectionGroup,
} from "firebase/firestore/lite";

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCMyv9PGJSG5_Kan_6f_OIrKtbMgK94zOo",
    authDomain: "enviepix-9f42e.firebaseapp.com",
    projectId: "enviepix-9f42e",
    storageBucket: "enviepix-9f42e.appspot.com",
    messagingSenderId: "504360453580",
    appId: "1:504360453580:web:d17ed74011b10218134969",
    measurementId: "G-H7C1SF9EXB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const analytics = getAnalytics(app);

export const createLabel = async (label: LabelOnFirebase, userID: string) => {
    const date = new Date();
    const key = dateFormat(date, "yyyy-mm-dd_HH:MM:ss");
    label.date = date.getTime();
    await setDoc(doc(db, `prints/${userID}/labels`, key), label);
    console.log(`Documento criado com no Firebase`);
};

//é necessário criar um index composto para esta query com os campos c_key e date
//como fica: labels	c_key Ascending date Descending	Collection		Enabled
//na primeira vez que ela é executada, o erro  na console vem com o link para
//automaticamente criar o link
export const getLabel = async (label: LabelOnFirebase, userID: string) => {
    const labelsRef = collection(db, `prints/${userID}/labels`);
    const q = query(
        labelsRef,
        where("c_key", "==", `${label.type}:${label.key}`),
        orderBy("date", "desc"),
        limit(1)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        return doc.data(); //retorna o primeiro (e único elemento, pois limit == 1)
    });
    return undefined;
};

//Veja https://firebase.googleblog.com/2019/06/understanding-collection-group-queries.html
//para configurar as regras de segurança e outros detalhes numa collection
export const getLabelByKey = async (key: string) => {
    const labelsRef = collectionGroup(db, "labels");
    const q = query(
        labelsRef,
        where("key", "==", key),
        orderBy("date", "desc"),
        limit(1)
    );
    const querySnapshot = await getDocs(q);

    let ret;
    querySnapshot.forEach((doc) => {
        //console.log(JSON.stringify(doc.data()));
        ret = doc.data(); //retorna o primeiro (e único elemento, pois limit == 1)
    });
    return ret;
};

export const getAppInfo = async () => {
    const docSnap = await getDoc(doc(db, "app", "info"));
    if (docSnap.exists()) {
        //console.log(`Doc: ${docSnap.data()}`);
        return docSnap.data();
    } else return null;
};
