<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons v-if="showBackArrow=='true'" slot="start">
                    <ion-back-button :default-href="pageDefaultBackLink"></ion-back-button>
                </ion-buttons>
                <ion-buttons v-if="loggedIn" slot="end">
                    <ion-button @click="logout">Logout</ion-button>
                </ion-buttons>
                <ion-title>{{pageTitle}}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <slot />
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonBackButton,
    IonButtons,
    IonButton
} from "@ionic/vue";
import { ref, onMounted, inject } from "vue";
import { useRouter } from 'vue-router';
import { getAuth, signOut   } from "firebase/auth";

export default {
    props: ['pageTitle', 'pageDefaultBackLink', 'showBackArrow'],

    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonBackButton,
        IonButtons,
        IonButton
    },

    setup() {
        const loggedIn = ref(false);

        const auth = getAuth();
        const router = useRouter();

        const emitter = inject("emitter");

        function logout() {
            signOut(auth).then(() => {
                console.log('BaseLayout: Logout');
                loggedIn.value = false;
                emitter.emit("logged_out");
            })
        }

        onMounted(() => {
            console.log('BaseLayout Mounted');
            if (! getAuth().currentUser) {
                console.log('BaseLayout: Não logado')
                loggedIn.value = false;
            } else {
                loggedIn.value = true;
            }
        })

        emitter.on('logged_out', () => {
            loggedIn.value = false;
            console.log('BaseLayout: Recebi um logged_out')
            router.push('/signin')
        })

        emitter.on('logged_in', () => {
            console.log('BaseLayout: Recebi um logged_in')
            loggedIn.value = true;
            router.push('/')
        })

        return {
            logout,
            loggedIn
        }
    }
}
</script>