

import {
    IonLabel,
    IonInput,
    IonItem,
    IonList,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonImg,
    IonRadio, 
    IonRadioGroup
} from "@ionic/vue";

import { defineComponent, ref, reactive, computed } from "vue";

import { useRouter } from 'vue-router';
import { getAuth } from "firebase/auth";
import BaseLayout from "@/components/BaseLayout.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getLabelByKey } from "@/firebase/firebase";
import { Geolocation } from '@capacitor/geolocation';
import  { fingerprint } from "../../libs/signing"
import QRCodeStyling, { ErrorCorrectionLevel } from 'qr-code-styling';

import { useStore } from 'vuex';

export default defineComponent({
    name: "QRGenerator",
    components: {
        IonLabel,
        IonItem,
        IonList,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonButton,
        IonImg,
        BaseLayout,
        IonRadio, 
        IonRadioGroup
    },

    setup() {
        const name = ref("");
        const key = ref("");
        const type = ref("");
        const city = ref("");
        const ammount = ref("");
        const qrcode = ref("");
        const qrtxt = ref("");
        const fec = ref("Q");
        const location = reactive({'longitude': 0, 'latitude': 0, 'accuracy': 0});
        const sign = ref("");
        const nameColor = ref("primary");
        const cityColor = ref("primary");
        const canCar = ref(false);
        const wifiType = ref("WPA");
        const wifiKey = ref("")

        //const qrcodeString = ref("");
        const store = useStore();
        const router = useRouter();
        const appInfo = store.getters.getAppInfo;

        let privkey = "";
        //let iv = "000";
        if (appInfo && 'signingkey' in appInfo) {
            privkey = appInfo.signingkey;
            //iv = appInfo.iv;
        }

        setInterval(()=>{
        if (! getAuth().currentUser)
            router.push("/signin");
        }, 2000);

        const itsOkToGenerate = computed(() => {
            if (
                key.value !== "" && 
                type.value !== "" 
                ) {
                    return true;
            }
            return false
        });

        async function blobToBase64(blob: Blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise(resolve => {
                reader.onload = () => {
                    resolve(reader.result);
                }
            })
        }

        // async function openToast(message: string) {
        //     const toast = await toastController
        //         .create({
        //         message: message,
        //         duration: 2000
        //         })
        //     return toast.present();
        // }

        async function generate() {
            let result = key.value;
            let image = '/assets/icon/instagram.png'
            switch (type.value) {
                case('instagram') : {
                    if (result.startsWith('@')) {
                        result = result.substring(1);
                    } 
                    result = 'instagram://user?username=' + result;
                    image = '/assets/icon/instagram.png';
                    break;
                }
                case('whatsapp') : {
                    result = result.replace('-','').replace('(','').replace(')','')
                    if (!result.startsWith('+55')) {
                        result = '+55'.concat(result);
                    }
                    result = 'https://wa.me/'+result+'/?text=Ola%21'
                    image = '/assets/icon/whatsapp.png';
                    break;
                }
                case('twitter') : {
                    if (result.startsWith('@')) {
                        result = result.substring(1);
                    } 
                    result = 'twitter://user?screen_name=' + result;
                    image = '/assets/icon/twitter.png';
                    break;
                }
                case('facebook') : {
                    if (result.startsWith('@')) {
                        result = result.substring(1);
                    } 
                    result = 'https://www.facebook.com/' + result;
                    image = '/assets/icon/facebook.png';
                    break;
                }
                case('site') : {
                    if (result.startsWith('@')) {
                        result = result.substring(1);
                    } 
                    //result = result;
                    image = '/assets/icon/www.png';
                    break;
                }
                case('wifi') : {
                    if (result.startsWith('@')) {
                        result = result.substring(1);
                    } 
                    result = 'WIFI:S:' + key.value +';T:' + 
                        wifiType.value +';P:' + wifiKey.value + ';;'
                    image = '/assets/icon/wifi.png';
                    break;
                }
                case('telegram') : {
                    if (result.startsWith('@')) {
                        result = result.substring(1);
                    } 
                    result = 'https://t.me/' + result;
                    image = '/assets/icon/telegram.png';
                    break;
                }
                case('youtube') : {
                    if (result.startsWith('@')) {
                        result = result.substring(1);
                    } 
                    result = 'https://www.youtube.com/c/' + result;
                    image = '/assets/icon/youtube.png';
                    break;
                }
            }

            const QRCode = new QRCodeStyling({
                width: 400,
                height: 400,
                type: 'canvas',
                data: result,
                image: image,
                qrOptions : {
                    typeNumber: 0,
                    mode: 'Byte',
                    errorCorrectionLevel: fec.value as ErrorCorrectionLevel
                },
                imageOptions: {
                    hideBackgroundDots: true,
                    imageSize: 0.4,
                    margin: 5,
                    crossOrigin: 'anonymous'
                },
                dotsOptions: {
                    color: "#000000",
                    type: 'extra-rounded'
                },
                backgroundOptions : {
                    color: '#ffffff'
                },
                cornersSquareOptions: {
                    color: '#000000',
                    type : 'extra-rounded'
                },
                cornersDotOptions: {
                    color: '#000000',
                    type: 'dot'
                }
            });

            const coordinates = await Geolocation.getCurrentPosition();
            location.latitude = coordinates.coords.latitude;
            location.longitude = coordinates.coords.longitude;
            location.accuracy = coordinates.coords.accuracy;
            
            QRCode.getRawData('png').then(async value => {
               
                if(value) {
                    qrcode.value = await blobToBase64(value) as string
                    //console.log(qrcode.value);
                    sign.value = fingerprint(key.value, privkey);
                    //qrcodeString.value = result.toString();
                    qrtxt.value = result;
                    canCar.value = true;
                }
            }, reason => {
                console.log('Erro!')
                console.log(reason)
            });

        }

        function clear() {
            qrcode.value = "";
            key.value = "";
            name.value = "";
            type.value = "";
            city.value = "";
            ammount.value = "";
            location.latitude = 0;
            location.longitude = 0;
            location.accuracy = 0;
            canCar.value = false;
        }

        async function doCar() {
            if (itsOkToGenerate.value) {
                await store.commit('setLabel',{
                    key: key.value,
                    city: city.value,
                    name: name.value,
                    type: type.value,
                    ammount: ammount.value,
                    qrcode: qrcode.value,
                    qrtxt: qrtxt.value,
                    location,
                    fec: fec.value,
                    sign : sign.value
                });
                router.push("/car");
            }
        }


        return {
            name,
            key,
            type,
            qrcode,
            city,
            ammount,
            fec,
            generate,
            clear,
            doCar,
            itsOkToGenerate,
            location,
            nameColor,
            cityColor,
            canCar,
            wifiType,
            wifiKey 
        };
    },
});
