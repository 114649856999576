
import { createStore } from "vuex";
import { getAuth } from "firebase/auth";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createLabel, getAppInfo, getLabel, getLabelByKey } from "../firebase/firebase";

// const uids = {
//     "rogerio.cunha@gmail.com" : "6aNjI4KEAVWhCCMkS11PUaEnYD62",
//     "idavan@idavan.com": "PzPx8oj4zfUNADDiMrXzBJsB5zF2"
//     }
    
// const defaultUser = "rogerio.cunha@gmail.com";

type LabelOnFirebase = {
    name: string;
    key: string;
    type: string;
    city: string;
    ammount: string;
    fec: string;
    location: {};
    c_key: string;
    paid: boolean;
    date: Date;
    qrcode: string;
    qrtxt: string;
    sign: string;
    car: {
        "Etiqueta Pequena": number;
        "Etiqueta Grande": number;
        "Suporte": number;
        "Crachá": number;
    };
}

type labelType = {
    name: string;
    key: string;
    type: string;
    city: string;
    ammount: string;
    fec: string;
    location: {};
    qrcode: string;
    qrtxt: string;
    sign: string;

  };

  
const store = createStore({

    state: {
        label : {
            name: '',
            key: '',
            type: '',
            city: '',
            fec: '',
            ammount: '',
            location: {},
            // eslint-disable-next-line @typescript-eslint/camelcase
            c_key: '',
            paid: false,
            date: '',
            qrtxt: '',
            qrcode: '',
            sign: '',
            car: {
                "Etiqueta Pequena": 0,
                "Etiqueta Grande": 0,
                "Suporte": 0,
                "Crachá" : 0
            }
        },
        appInfo : {
            signingkey : '',
            iv : '',
            version : [],
            prices : {
                'Etiqueta Pequena' : 0,
                'Etiqueta Grande': 0,
                'Suporte' : 0,
                'Crachá': 0
            }
        },
        autoLogin: true,
        business: 'pix'
    },

    getters: {
        getLabel(state: any) {
            return state.label;
        },
    
        getAppInfo(state: any) {
            return state.appInfo;
        },

        getBusiness(state: any) {
            return state.business;
        }
    },

    mutations: {

        setBusiness (state,value) {
            state.business = value;
        },

        async setLabel(state, value) {
            state.label = Object.assign({},value);
        },

        setAppInfo(state, value) {
            state.appInfo = Object.assign({},value);
        },

        async saveOnFirebase(state) {
            const auth = getAuth();
            if (auth && auth.currentUser) {
                const label = Object.assign({},state.label);
                label["date"] = "";
                label["paid"] = false;
                label.key = label.key.trim();
                if (label.type !== "chave" && label.type !== "email")
                    label.key = label.key.replace(/\D/g,'');
                label["c_key"] = label.type + ':' + label.key;
                label["c_key"] = label["c_key"].toLowerCase();
                label.qrcode = ""; //qrcode não é salvo no firebase, apenas no state, para economizar
                await createLabel(label, auth.currentUser.uid);//uids[defaultUser])
            }
        }
    },

    actions : {},
    modules : {},
});

export default store;