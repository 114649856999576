import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import PixQR from '../views/pix/QRGenerator.vue';
import PixLabel from '../views/pix/Label.vue';
import SocialQR from '../views/social/QRGenerator.vue';
import SocialLabel from '../views/social/Label.vue';
import VcardQR from '../views/vcard/QRGenerator.vue';
import VcardLabel from '../views/vcard/Label.vue';
import FrontPage from '../views/FrontPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/frontpage",
  },
  {
    path: "/home",
    redirect: "/frontpage",
    component : FrontPage,
  },
  {
    path: "/frontpage",
    name: "FrontPage",
    component: FrontPage,
  },
  {
    path: "/pix/qrgenerator",
    name: "PixQR",
    component: PixQR,
  },
  {
    path: "/pix/label",
    name: "PixLabel",
    component: PixLabel,
  },
  {
    path: "/social/qrgenerator",
    name: "SocialQR",
    component: SocialQR,
  },
  {
    path: "/social/label",
    name: "SocialLabel",
    component: SocialLabel,
  },
  {
    path: "/vcard/qrgenerator",
    name: "VcardQR",
    component: VcardQR,
  },
  {
    path: "/vcard/label",
    name: "VcardLabel",
    component: VcardLabel,
  },
  {
    path: "/signin",
    name: "SignIn",
    component: () => import('../components/SignIn.vue')
  },
  {
    path: "/car",
    name: "Car",
    component: () => import('../views/Car.vue')
  },
  {
    path: "/login",
    redirect: "/signin",
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
