

import {
  IonItem,
  IonList,
  IonButton,
} from "@ionic/vue";

import BaseLayout from "@/components/BaseLayout.vue";
import { useRouter } from 'vue-router';
import { getAuth } from "firebase/auth";
import { useStore } from 'vuex';

export default {
    name: "Etiqueta",
    components: {
        IonItem,
        IonList,
        IonButton,
        BaseLayout,
    },

    setup() {
        const router = useRouter();
        const store = useStore();

        setInterval(()=>{
            if (! getAuth().currentUser)
                router.push("/signin");
        }, 2000);
        
        function pix() {
            store.commit('setBusiness','pix');
            router.push('/pix/qrgenerator');
        }
        function social() {
            store.commit('setBusiness','social');
            router.push('/social/qrgenerator');
        }
        function vcard() {
            store.commit('setBusiness','vcard');
            router.push('/vcard/qrgenerator');
        }
        return {
            pix,
            social,
            vcard
        }
    }
}
