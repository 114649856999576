

import {
    IonLabel,
    IonInput,
    IonItem,
    IonButton,
    IonCheckbox,
    IonImg,
    toastController, 
    IonTextarea
} from "@ionic/vue";

import { defineComponent, ref, reactive, computed } from "vue";

import { useRouter } from 'vue-router';
import { getAuth } from "firebase/auth";
import BaseLayout from "@/components/BaseLayout.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getLabelByKey } from "@/firebase/firebase";
import { Geolocation } from '@capacitor/geolocation';
import  { fingerprint } from "../../libs/signing"
import QRCodeStyling, { ErrorCorrectionLevel } from 'qr-code-styling';
// import { TypeParameter, URIType, TextType, FNProperty, NProperty, SpecialValueType, AdrProperty, TextListType, TelProperty, ValueParameter } from 'vcard4';

import { useStore } from 'vuex';

export default defineComponent({
    name: "QRGenerator",
    components: {
        IonLabel,
        IonItem,
        IonInput,
        IonButton,
        IonCheckbox,
        IonImg,
        IonTextarea, 
        BaseLayout,
    },

    setup() {
        const name = ref("");
        const key = ref("");
        const type = ref("comum");
        const city = ref("");
        const ammount = ref("");
        const qrcode = ref("");
        const qrtxt = ref("");
        const fec = ref("L");
        const location = reactive({'longitude': 0, 'latitude': 0, 'accuracy': 0});
        const sign = ref("");
        const nameColor = ref("primary");
        const cityColor = ref("primary");
        const canCar = ref(false);
        const cel = ref("");
        const tel = ref("");
        const email = ref("");
        const site = ref("");
        const address = ref("");
        const isWork = ref(false)

        //const qrcodeString = ref("");
        const store = useStore();
        const router = useRouter();
        const appInfo = store.getters.getAppInfo;

        let privkey = "";
        //let iv = "000";
        if (appInfo && 'signingkey' in appInfo) {
            privkey = appInfo.signingkey;
            //iv = appInfo.iv;
        }
        
        async function example() {
            name.value = "José da Silva";
            cel.value = "(21)91234-5678";
            tel.value = "(21)2678-1234";
            email.value = "jose.silva.1234@gmail.com";
            site.value = "https://www.josesilva.eng.br";
            address.value = "Rua da Independência, 20033 Bloco B/303;Rio de Janeiro;RJ;22220-000";
        }

        
        setInterval(()=>{
        if (! getAuth().currentUser)
            router.push("/signin");
        }, 2000);

        const itsOkToGenerate = computed(() => {
            if (
                name.value !== "" 
                ) {
                    return true;
            }
            return false
        });

        async function blobToBase64(blob: Blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise(resolve => {
                reader.onload = () => {
                    resolve(reader.result);
                }
            })
        }

        async function openToast(message: string) {
            const toast = await toastController
                .create({
                message: message,
                duration: 2000
                })
            return toast.present();
        }

        async function generate() {
            
            const image = '/assets/icon/vcard.png'

            let work = "TYPE=home";
            if (isWork.value) {
                work = "${work}"
            }
            
            if (address.value!== "" && address.value.split(';').length !== 4) {
                openToast('Entre com o endereço separado por ";" com 4 campos');
                return;
            }

            const vcard: string[] = [];
            //veja template aqui...
            //https://kelseykm.github.io/vcard4/getting-started/tutorial/creating-vcard
            //https://en.wikipedia.org/wiki/VCard (Melhor!)

            vcard.push('BEGIN:VCARD')
            vcard.push('VERSION:4.0')
            vcard.push(`FN:${name.value.trim()}`)
            // if (name.value.trim().includes(" ")) {
            //     vcard.push(`N:${name.value.trim().split(" ")[1]};${name.value.trim().split(" ")[0]};;;`)
            // }
            if (address.value!== "") {
                vcard.push(`ADR;${work}:;;${address.value};Brasil`)
            }
            if (cel.value !== "") {
                vcard.push(`TEL;VALUE=uri;TYPE="cell";PREF=1:tel:+55${cel.value}`)
            }
            if (tel.value !== "") {
                vcard.push(`TEL;VALUE=uri;TYPE="voice":tel:+55${tel.value}`)
            }
            if (email.value !== "") {
                vcard.push(`EMAIL;${work}:${email.value}`)
            }
            if (site.value !== "") {
                vcard.push(`URL;${work}:${site.value}`)
            }
            vcard.push(`END:VCARD`)
            
            key.value = vcard.join('\n');
            qrtxt.value = key.value;

            const QRCode = new QRCodeStyling({
                width: 400,
                height: 400,
                type: 'canvas',
                image: image,
                data: key.value,
                qrOptions : {
                    typeNumber: 0,
                    mode: 'Byte',
                    errorCorrectionLevel: fec.value as ErrorCorrectionLevel
                },
                imageOptions: {
                    hideBackgroundDots: true,
                    imageSize: 0.4,
                    margin: 2,
                    crossOrigin: 'anonymous'
                },
                dotsOptions: {
                    color: "#000000",
                    type: 'square'
                },
                backgroundOptions : {
                    color: '#ffffff'
                },
                cornersSquareOptions: {
                    color: '#000000',
                    type : 'square'
                },
                cornersDotOptions: {
                    color: '#000000',
                    type: 'square'
                }
            });

            const coordinates = await Geolocation.getCurrentPosition();
            location.latitude = coordinates.coords.latitude;
            location.longitude = coordinates.coords.longitude;
            location.accuracy = coordinates.coords.accuracy;
            
            QRCode.getRawData('png').then(async value => {
               
                if(value) {
                    qrcode.value = await blobToBase64(value) as string
                    //console.log(qrcode.value);
                    sign.value = fingerprint(key.value, privkey);
                    //qrcodeString.value = result.toString();
                    canCar.value = true;
                }
            }, reason => {
                console.log('Erro!')
                console.log(reason)
            });
        }

        function clear() {
            qrcode.value = "";
            key.value = "";
            name.value = "";
            type.value = "";
            city.value = "";
            ammount.value = "";
            location.latitude = 0;
            location.longitude = 0;
            location.accuracy = 0;
            canCar.value = false;
            cel.value="";
            tel.value="";
            email.value="";
            site.value = "";
            address.value = "";
        }

        async function doCar() {
            if (itsOkToGenerate.value) {
                await store.commit('setLabel',{
                    key: key.value,
                    city: city.value,
                    name: name.value,
                    type: type.value,
                    ammount: ammount.value,
                    qrcode: qrcode.value,
                    qrtxt: qrtxt.value,
                    location,
                    fec: fec.value,
                    sign : sign.value
                });
                router.push("/car");
            }
        }


        return {
            name,
            key,
            type,
            qrcode,
            city,
            ammount,
            fec,
            generate,
            clear,
            doCar,
            itsOkToGenerate,
            location,
            nameColor,
            cityColor,
            canCar,
            cel,
            tel,
            email,
            site,
            address,
            isWork,
            example
        };
    },
});
